import PropTypes from 'prop-types'

import Image from 'next/image'

import CSS from './speaker-card.module.css'

export const SpeakerCard = ({ url, photoUrl, fullName, jobTitle, company }) => {
    return (
        <a href={url} className={CSS.layout}>
            
            <div className={CSS.photoContainer}>
                <Image src={photoUrl} layout='fill' alt=""/>
            </div>

            <div className='pt-xs'>
                <p className='fs-5 fw-600 caps c-primary'>{ fullName }</p>
                <p>{ jobTitle }</p>
                <p className='fw-500'>{ company }</p>
            </div>

        </a>
    )
}

SpeakerCard.propTypes = {
    url: PropTypes.string,
    photoUrl: PropTypes.string,
    fullName: PropTypes.string,
    jobTitle: PropTypes.string,
    company: PropTypes.string,
}

SpeakerCard.defaultProps = {
    url: null,
    photoUrl: "https://picsum.photos/600/600",
    fullName: "Forename Surname",
    jobTitle: "Job Title or Position",
    company: "Company or Organisation",
}