import Image from "next/image"

import CSS from '../css/app/partners.module.css'


const partnerLevels = [
    { level: 1, label: 'Platinum' },
    { level: 2, label: 'Gold' },
    { level: 3, label: 'Silver' },
    { level: 4, label: 'Bronze' },
    { level: 5, label: 'Media Partner' },
    { level: 6, label: 'Press' },
    { level: 7, label: 'Startup Alley' },
    { level: 8, label: 'Host Partner' },
    { level: 9, label: 'Knowledge Partner' }
]



export const FilterAllBizzaboParterLevels = ( data ) => {

    partnerLevels.forEach( level => {

        let currentLevelPartners = data.filter( partner => partner.level === level.level )
        // console.log('all levels:', {
        //     label: level.label,
        //     partners: currentLevelPartners
        // })
        return {
            label: level.label,
            partners: currentLevelPartners
        }

    })

}

export const FilterBizzaboParterLevel = ( levelId, data ) => {

    let currentLevelLabel = partnerLevels.filter( level => level.level === levelId )
    let currentLevelPartners = data.filter( partner => partner.level === levelId )

    return (
        <div className="container center pt-sm">
            <p className="fw-500 caps c-secondary mb-sm">{currentLevelLabel[0].label}</p>
            {
                currentLevelPartners.map( partner => (
                    <a key={partner.id} href={`/partners/${partner.id}`} rel="noreferrer" aria-label="Partner logo">

                        <div className={CSS.logo}>
                            <Image 
                                src={partner.logo} 
                                width={500}
                                height={400}
                                layout='responsive'
                                alt=""
                            />
                        </div>

                    </a>
                ))
            }
        </div>
    )

}


export const GetBizzaboPartnerLevelTitle = ( id ) => {
    let index = id - 1
    let title = partnerLevels[index].label
    return title
}