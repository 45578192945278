import { useEffect } from 'react'

import { gql } from "@apollo/client"
import craftApolloClient from "./api/apollo"

import { gsap } from "gsap/dist/gsap"
import { ScrollTrigger } from "gsap/dist/ScrollTrigger"
import { Tween } from 'react-gsap'

import AOS from "aos"
import "aos/dist/aos.css"


import Head from 'next/head'
import Image from 'next/image'

import { HeroHeader } from '../fuselage/global/hero-header/hero-header'
import { RenewHeroUnitVideo } from '../fuselage/blocks/hero-unit-video/hero-unit-video'
import { StickySidebar } from '../fuselage/layout/sticky-sidebar/sticky-sidebar'
import { Button } from '../fuselage/components/button/button'
import { ArrowButton } from '../fuselage/components/arrow-button/arrow-button'
import { ImpactInfograph } from '../fuselage/components/impact-infograph/impact-infograph'
import { FinancialImpact } from '../fuselage/components/financial-impact/financial-impact'
import { SpeakerCarousel } from '../fuselage/blocks/speaker-carousel/speaker-carousel'
import { CrowdMaker } from '../fuselage/components/crowd-maker/crowd-maker'
import { SpeakerCard } from "../fuselage/components/speaker-card/speaker-card"
import { Poster } from '../fuselage/components/poster/poster'
import HubspotForm from '../fuselage/components/hubspot-form/hubspot-form'

import { FilterBizzaboParterLevel } from '../lib/bizzabo-partner-levels'

import CSS from '../css/app/index.module.css'


import craftSelectedSpeakers from '../fixtures/craftcms-selected-speakers.json'

export default function Home({ seomatic, speakersData, partnerData }) {

	// seo

	let metaTitle = JSON.parse(seomatic.metaTitleContainer)
    let metaTags = JSON.parse(seomatic.metaTagContainer) 

	// filter speakers for carousel
	
	const bizzaboSpeakers = speakersData.content
	const craftSpeakers = craftSelectedSpeakers.map(id => parseInt(id))
	const selectedSpeakers = bizzaboSpeakers.filter(item => craftSpeakers.includes(item.id));
	
	console.log('bizzaboSpeakers:', bizzaboSpeakers.map(speaker => speaker.id))
	console.log('craftSpeakers:', craftSpeakers)
	console.log('filtered speakers:', selectedSpeakers)


	// init gsap
	
	
	useEffect( () => {
		gsap.registerPlugin(ScrollTrigger)
	})

	useEffect( () => {
        AOS.init({ offset: 500 })
        AOS.refresh()
    }, [])

	return (
		<>
			<Head>
				<title>{metaTitle.title.title}</title>

				<meta name="description" content={metaTags.description.content} />
				<meta name="referrer" content={metaTags.referrer.content} />
				{/* <meta name="robots" content={metaTags.robots.content} /> */}
				<meta content={metaTags['og:locale'].content} property="og:locale" />
				<meta content={metaTags['og:site_name'].content} property="og:site_name" />
				<meta content={metaTags['og:type'].content} property="og:type" />
				<meta content={metaTags['og:url'].content} property="og:url" />
				<meta content={metaTags['og:title'].content} property="og:title" />
				<meta content={metaTags['og:description'].content} property="og:description" />
				<meta content={metaTags['og:image'].content} property="og:image"></meta>  
			</Head>


			<div className="bg-dark mainContent">


				<HeroHeader fixed inverse />


				<RenewHeroUnitVideo eventInfo={{ 
					date: "Date & Location: To be confirmed", 
					location: "",
					ctaButtonLink: "/register-interest",
					ctaButtonLabel: "Register your interest"
				}}/>



				{/* globe */}

				<Tween
					to={{
						y: `-100%`,
						scrollTrigger: {
							// markers: { startColor: 'lime', endColor: 'lime'},
							trigger: `.mainContent`,
							start: 'top top',
							end: 'bottom top',
							scrub: true,
						}
					}}
				>

					<div className={CSS.globe}>
						<Image src='/assets/homepage/globe.jpg' layout='fill' alt='globe' />
					</div>
				</Tween>



				{/* infographs */}

				<section className="block">


					<div className="container" style={{ position: `relative`, zIndex: 2 }}>
						<p className={`${CSS.highlight} fs-lg fw-700 lh-1 c-primary maxw-60`}>A GLOBAL EVENT TO <span>RENEW</span> HOW WE INVEST IN <span>R</span>EAL <span>E</span>STATE FOR A <span>NE</span>W <span>W</span>ORLD.</p>
					</div>

					<div className="container minvh-10" style={{ borderLeft: `1px solid var(--primary)`}}></div>


					<div className="container">
						<StickySidebar>
							<aside className='maxw-40'>

								<p className="fs-0 fw-700 c-primary lh-1">BUILDINGS ARE <span className='c-white'>DESTROYING THE PLANET</span></p>
								<p className="fs-4 mt-sm">We spend close to 90% of our lives in the built environment, yet these buildings that encompass all this life are destroying our planet.</p>

								<div className={CSS.longArrowGrid}>
									<p className='fs-2 fw-500 c-primary lh-1'>BUILDINGS ARE RESPONSIBLE FOR</p>
													
									<img id='longArrow1' className={CSS.longArrow} src="/assets/homepage/long-arrow.svg" alt="right-arrow" data-aos='fade-right' data-aos-offset='200'/>
								</div>

							</aside>

							<main>

								<div className={CSS.desktopSpacer}></div>

								<div className='fadeUp'>
									<ImpactInfograph 
										impactTotal={40} 
										graphicUrl={"/assets/theme/2022/theme-icon-energy.svg"}
										id='barOne'
									>
										<p className='fs-3 fw-500 lh-1'><span className='c-primary'>of global</span> <br/>energy consumption</p>
									</ImpactInfograph>
								</div>
								
								<div className='fadeUp'>
									<ImpactInfograph 
										impactTotal={37} 
										graphicUrl={"/assets/theme/2022/theme-icon-gas.svg"}
										id="barTwo"
									>
										<p className='fs-3 fw-500 lh-1'><span className='c-primary'>of global</span> <br/>greenhouse gas emissions</p>
									</ImpactInfograph>
								</div>
								
								<div className='fadeUp'>
									<ImpactInfograph 
										impactTotal={12} 
										graphicUrl={"/assets/theme/2022/theme-icon-water.svg"}
										id="barThree"
									>
										<p className='fs-3 fw-500 lh-1'><span className='c-primary'>of global</span> <br/>fresh water consumption</p>
									</ImpactInfograph>
								</div>
								
								<div className='fadeUp'>
									<ImpactInfograph 
										impactTotal={40} 
										graphicUrl={"/assets/theme/2022/theme-icon-landfill.svg"}
										id="barFour"
									>
										<p className='fs-3 fw-500 lh-1'><span className='c-primary'>of global</span> <br/>landfill waste</p>
									</ImpactInfograph>
								</div>

								<div className='maxw-40 formatted'>
									<p className='fs-5 '>The evidence of the mounting damage is all around us. The most significant is climate change, with the planet heating up with rising greenhouse gas emissions. As a result, natural disasters from floods to hurricanes and storms are becoming more frequent, while heatwaves continue for weeks with little relief.</p>
									<p className='fs-5 '>Being one of the most significant contributors to greenhouse gas emissions, buildings can be the champions to stop the irreversible effects of climate change before it is too late.</p>
								</div>
							</main>
						</StickySidebar>
					</div>
				</section>


				{/* financial impact */}

				<section className="block">
					<div className="container">
						<StickySidebar>
							<aside className='maxw-50 formatted'>
								<p className="fs-0 fw-700 c-primary lh-1"><span className='c-white'>BETTER</span> BUILDINGS<br/><span className='c-white'>BETTER</span> PLANET <br/><span className='c-white'>BETTER</span> PROFITS</p>
								
								<p className="fs-4 mt-sm">Investors and financiers are finding sustainable buildings to be better investments not only for the planet but also for their bottom line.</p>

								<div className={CSS.longArrowGrid}>
									<p className='fs-2 fw-500 c-primary caps lh-1'>Growing Market Opportunities Include</p>
									<img id='longArrow1' className={CSS.longArrow} src="/assets/homepage/long-arrow.svg" alt="right-arrow" data-aos='fade-right' data-aos-offset='200'/>					
								</div>
							</aside>

							<main>

								<div className="right my-md">
									<img className={CSS.themeGraphic} src="/assets/homepage/theme-blocks.svg" alt="" />
								</div>

								<div className='fadeUp'>
									<FinancialImpact id='1' currency='US$' impactTotal={24.7} ion='trillion'>
										<p className='fs-3 fw-400'>By 2030, green building opportunities in emerging markets are estimated to be</p>
									</FinancialImpact>
								</div>
								
								<div className='fadeUp'>
									<FinancialImpact id='2' currency='US$' impactTotal={610} ion='billion'>
										<p className='fs-3 fw-400'>By 2028, green building materials market opportunities are expected to reach</p>
									</FinancialImpact>
								</div>
					
								<div className='fadeUp'>
									<FinancialImpact id='3' currency='US$' impactTotal={206} ion='billion'>
										<p className='fs-3 fw-400'>Construction of new green-certified buildings in 2020 averaged</p>
									</FinancialImpact>
								</div>
					
								<div className='fadeUp'>
									<FinancialImpact id='4' currency='US$' impactTotal={103.08} ion='billion'>
										<p className='fs-3 fw-400'>By 2023, the global non-residential green buildings market is expected to reach</p>
									</FinancialImpact>
								</div>

								<div className='maxw-50 formatted'>
									<p className='fs-5 '>Certified green buildings can lower operating expenses and improve resource efficiency by reducing utility bills, waste output, and maintenance costs. Green buildings can also increase revenues through higher rent, better occupancy rates, and higher sale prices.</p>
									<p className='fs-5 '>The business case for a sustainable built environment becomes stronger when considering the broader value proposition of climate action and social value alongside the financial benefits and risk mitigation.</p>
								</div>
							</main>

							
						</StickySidebar>
					</div>
				</section>



				<div style={{ position: `relative`, zIndex: 2 }}>

					{/* crowd */}

					<section className="block">
						<div className="container">
							<StickySidebar>
								<aside>
									<p className={`${CSS.highlight} fs-0 fw-700 lh-1 c-primary caps maxw-55 hyphenate`} style={{ hyphens: `auto`}}>ALONE WE CAN DO SO LITTLE. <span>TOGETHER WE CAN DO SO MUCH.</span></p>
									<p className='fs-5 mt-sm maxw-35'>Accelerating the agenda for sustainable real estate will require the joined effort from many stakeholders in parallel, like an orchestra. And like a beautiful symphony, impactful progress can only be made in harmony with one another.</p>
								</aside>

								<main>
									<div className={CSS.desktopSpacer}></div>
									<CrowdMaker />
								</main>

							</StickySidebar>
						</div>



						<div className="container columns-2 gap-md">
							<section>
								<img className={CSS.themeGraphic} src="/assets/homepage/theme-blocks.svg" alt="" />
							</section>
							<section className='formatted maxw-40'>
								<p className='fs-5'><span className='fw-600 c-primary'>The <span className='c-white'>RENEW</span> Investment Summit</span> will gather investors, governments, policymakers, and industry stakeholders from around the globe committed to making real change happen within the built environment.</p>
								<p className='fs-5'>The summit will provide a vital opportunity for dialogue and action on how the industry can work together to establish sustainable real estate as the new normal. The pillars of nature, innovation and human capital will provide a starting point on how we can tackle climate challenges in new ways that are both financially attractive and environmentally responsible.</p>
								<p className='mt-sm' style={{ overflow: `hidden` }}>
									<ArrowButton href="/topic-themes" large inverse theme="accent" >
										Explore the summit&apos;s topic themes
									</ArrowButton>
								</p>
								<p className='mt-xs'>
									<ArrowButton href="/attending" large inverse theme="accent" >
										Want to attend RENEW
									?</ArrowButton>
								</p>
							</section>
						</div>
					</section>


					{/* speakers */}

					<SpeakerCarousel>
						{
							selectedSpeakers.map( speaker => (
								<SpeakerCard
									key={speaker.id}
									url={`/speakers/${speaker.id}`}
									photoUrl={speaker.photoSet.large}
									fullName={`${speaker.firstname} ${speaker.lastname}`}
									jobTitle={speaker.title}
									company={speaker.company}
								/>
							))
						}
					</SpeakerCarousel>


					{/* cta form */}

					<section className="block">
						<div className="container">
							<div className="maxw-50">
								<p className='fs-1 fw-600 lh-1 caps'>Interested in learning more about RENEW?</p>
								<p className='fs-5 my-sm'>Sign up and be among the first to receive event updates such as newly confirmed speakers, when tickets are going live, market insights and reports and much more! You can easily unsubscribe at any time.</p>
								<HubspotForm portalId={'14527187'} formId={'a930f142-8dd3-4542-91f8-9659dcda91a1'} />
							</div>
						</div>
					</section>
				


					{/* neom */}

					{/* <Poster imageUrl="/assets/homepage/neom-drone.jpg">
						<aside className="container" style={{ overflow: `hidden` }}>
							<img className={CSS.neomBigType} src="/assets/homepage/neom.svg" alt="" />
						</aside>
					</Poster> */}


					{/* <section className="block bg-dark pt-0">
						<div className={`${CSS.neomBoxoutGrid} container`}>

							<div className={CSS.neomLogo}>
								<p className='caps pb-xs'>Host Partner</p>
								<Image 
									className='mx-auto' 
									src="/assets/site/neom-inverse@2x.png" 
									width={198}
									height={214}
									alt="NEOM"
								/>
							</div>

							<div className={`${CSS.neomBoxout} bg-primary c-secondary p-md formatted `}>
								<p className='fs-2 fw-400 maxw-50'>One of the boldest and most audacious sustainable real estate projects globally is NEOM, a city being built in Northwest Saudi Arabia.</p>

								<div className="maxw-50 mt-sm formatted">
									<p className='fs-5'>Participants at the RENEW Investment Summit will hear how NEOM is planning strategically, investing sustainably, and building strong connections between industry partners while meeting environmental goals that ensure future generations can enjoy a better planet.</p>
								</div>
								<div className="mt-md">
									<Button 
										href="/partners/377799" 
										decal="fw-700 fs-5" 
										outline
									>
										Learn more about NEOM
									</Button>
								</div>
							</div>

						</div>
					</section> */}
				

					{/* ctas */}

					<section className="block" style={{ width: `100%`, overflow: `hidden` }}>
						<div className="container">
							<section>
								<p className={`${CSS.highlight} fs-0
								 fw-700 lh-1 c-primary caps maxw-45`}>Want to get involved in <span>RENEW</span>?</p>
							</section>

							<section className='mt-md columns-3 gap-sm formatted'>
								<div className={CSS.ctaCard}>
									<div className={CSS.ctaIcon}>
										<Image width={280} height={260} layout='responsive' src="/assets/homepage/attendees.svg" alt="" />
									</div>
									<p className='center'>
										<ArrowButton href="/attending" large inverse theme="accent">
											Register interest to attend
										</ArrowButton>
									</p>
								</div>
								<div className={CSS.ctaCard}>
									<div className={CSS.ctaIcon}>
										<Image width={280} height={260} layout='responsive' src="/assets/homepage/speaker.svg" alt="" />
									</div>
									<p className='center'>
										<ArrowButton href="https://y18xq4lqath.typeform.com/to/tdP2Xa8a" rel="noreferrer" target="_blank" large inverse theme="accent">
											Apply now to speak
										</ArrowButton>
									</p>
								</div>
								<div className={CSS.ctaCard}>
									<div className={CSS.ctaIcon}>
										<Image width={280} height={260} layout='responsive' src="/assets/homepage/sponsor.svg" alt="" />
									</div>
									<p className='center'>
										<ArrowButton href="/become-a-sponsor" large inverse theme="accent">
											Become a sponsor
										</ArrowButton>
									</p>
								</div>
							</section>
						</div>
					</section>

										
					{/* partners */}
				
					<section className="block bg-white">
						<div className="container center">
							<p className="fs-1 fw-700 lh-1 c-secondary caps">SPONSORS <span>&amp; PARTNERS</span></p>
						</div>
						
						{/* { FilterBizzaboParterLevel( 8, partnerData ) } */}

						{ FilterBizzaboParterLevel( 9, partnerData ) }
                
					</section>

				</div>

			</div>


		</>
	)
}


export async function getStaticProps() {

	const cmsData = await craftApolloClient().query({
        query: gql`
			query SEO {
				entry(site: "renew", id: 3) {
					title
					seomatic (asArray: true) {
						metaTitleContainer
						metaTagContainer
					}
				}
			}
        `
    })


	const speakers = await fetch('https://api.bizzabo.com/api/speakers?eventId=378417', {
		"method": "GET",
		"headers": {
			"Content-Type": "application/json",
			"Authorization": "Bearer 67f61f9e-e897-4536-914e-3f47286650f9"
		}
	})
	const speakerData = await speakers.json()

	
	const partners = await fetch('https://api.bizzabo.com/api/partners?eventId=378417', {
		"method": "GET",
		"headers": {
			"Content-Type": "application/json",
			"Authorization": "Bearer 67f61f9e-e897-4536-914e-3f47286650f9"
		}
	})
	const partnerData = await partners.json()
	

	return {
		props: { 
			seomatic: cmsData.data.entry.seomatic,
			speakersData: speakerData, 
			partnerData: partnerData.content
		}
	}
}
