import { gsap } from "gsap/dist/gsap"
import { ScrollTrigger } from "gsap/dist/ScrollTrigger"
gsap.registerPlugin(ScrollTrigger)
import { Tween } from 'react-gsap'

import CSS from './impact-infograph.module.css'

export const ImpactInfograph = ({ children, graphicUrl, impactTotal, id }) => {
    return (
        <div className={CSS.container}>

            <Tween
                from={{
                    opacity: 0,
                    scale: 0.2,
                    scrollTrigger: {
                        // markers: { startColor: 'pink', endColor: 'pink'},
                        trigger: `#icon${id}`,
                        start: 'top 75%',
                        end: 'top+=100% 75%',
                        scrub: 0.5,
                    },
                }}
            >
                <img id={`icon${id}`} className={CSS.graphic} src={graphicUrl} alt="" />
            </Tween>

            <Tween
                to={{
                    width: `${impactTotal}%`,
                    scrollTrigger: {
                        // markers: { startColor: 'tomato', endColor: 'tomato'},
                        trigger: `#${id}`,
                        start: '-100px 75%',
                        end: '100px 75%',
                        scrub: 0.5,
                    },
                }}
            >
                <div id={id} className={CSS.bar} />
            </Tween>
            
            <div className={CSS.barLine}></div>

            <Tween
                to={{
                    duration: 1,
                    textContent: `+=${impactTotal}%`,
                    roundProps: 'textContent',
                    scrollTrigger: {
                        // markers: { startColor: 'tomato', endColor: 'tomato'},
                        trigger: `#${id}`,
                        start: '-100px 75%',
                        end: '100px 75%',
                        scrub: 0.5,
                    }
                }}
            >
                <span className={CSS.impactFigure}>0%</span>
            </Tween>

            {/* <p className={CSS.impactFigure}>{impactTotal}%</p> */}
            
            <div>
                { children }
            </div>
        </div>
    )
}