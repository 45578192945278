import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide'
import '@splidejs/react-splide/css'

import { ArrowButton } from "../../components/arrow-button/arrow-button"

import Chevron from './img/slider-chevron-right.svg'

import CSS from './speaker-carousel.module.css'

export const SpeakerCarousel = ({ children, decal }) => {
    return (

        <section className={`block ${decal}`}  style={{ position: `relative`, zIndex: 2 }}>
            <div className='container'>
                <p className="fs-0 fw-700 c-primary lh-1 caps">Renew 2022 Speakers</p>
            </div>

            <div className={`${CSS.layout} container`}>

                <aside>
                    <p className='fs-5'>Check out the industry's sustainability champions and pioneers taking the stage at RENEW to bring you bold ideas and spark converstations that accelerate our progress towards sustainable real estate as the new normal.</p>
                    <p className='mt-sm'>
                        <ArrowButton href="/speakers" large inverse theme='accent'>View all speakers</ArrowButton>
                    </p>
                </aside>

                
                <main>

                    <Splide hasTrack={false} aria-label="RENEW Speakers" options={{
                        pagination: false,
                        gap: '2rem',
                        perPage: 3,
                        perMove: 1,
                        breakpoints: {
                            600: {
                                gap: '1rem',
                                perPage: 2
                            }
                        }
                    }}>
                        <SplideTrack>
                            { children.map( (item, index) => {
                                return (
                                    <SplideSlide key={index}>
                                        { item }
                                    </SplideSlide>
                                )
                            })}

                        </SplideTrack>
                        
                        <div className="splide__arrows">
                            <button className={`${CSS.sliderButton} ${CSS.prev} splide__arrow splide__arrow--prev`}>
                                <Chevron />
                            </button>
                            
                            <button className={`${CSS.sliderButton} ${CSS.next} splide__arrow splide__arrow--next`}>
                                <Chevron />
                            </button>
                        </div>
                    </Splide>

                </main>

            </div>
        </section>

    )
}